import React from 'react';
import { MaterializeCommonStyles } from '../../MaterializeStylesCommons';
import { Redirect, Route, Switch } from 'react-router-dom';
import PrivateRoute from './privateRoute';
import PublicRoute from './PublicRoute';
import Home from '../views/public/home/home';
import Navbar from '../../Navbar/Navbar';
import LoginModal from '../../components/Login/LoginModal';
import Logout from '../../components/Login/Logout';
import { useTrackingUserSession } from '../../hooks/useTrackingUserSession';
import { MainSkipLinks } from '../../shared/Elements/SkipLinks/MainSkipLinks';
import { useTranslation } from 'react-i18next';
import { useSectors } from '../../hooks/useSectors';

const Footer = React.lazy(() => import('../../components/Footer/Footer'));
const PrivacyPolicy = React.lazy(() =>
  import('../views/public/privacyPolicy/PrivacyPolicy')
);
const Register = React.lazy(() => import('../../components/Register/Register'));
const RegisterWaitingValidation = React.lazy(() =>
  import('../../components/RegisterWaitingValidation/RegisterWaitingValidation')
);
const RegisterValidated = React.lazy(() =>
  import('../../components/RegisterValidated/RegisterValidated')
);
const ResetPassword = React.lazy(() =>
  import('../../components/ResetPassword/ResetPassword')
);
const NewPassword = React.lazy(() =>
  import('../../components/NewPassword/NewPassword')
);
const NewPasswordValidatedCorrect = React.lazy(() =>
  import('../../components/NewPassword/NewPasswordValidatedCorrect')
);
const RegisterValidatedCorrect = React.lazy(() =>
  import('../../components/RegisterValidated/RegisterValidatedCorrect')
);
const RegisterValidatedIncorrect = React.lazy(() =>
  import('../../components/RegisterValidated/RegisterValidatedIncorrect')
);
const About = React.lazy(() => import('../views/public/about/about'));
const NotFound = React.lazy(() => import('../views/public/notFound/NotFound'));
const FrequentQuestions = React.lazy(() =>
  import('../views/public/faq/FrequentQuestions')
);
const Contact = React.lazy(() => import('../views/public/contact/contact'));
const YourMICA = React.lazy(() => import('../../components/YourMICA/YourMICA'));
const Artist = React.lazy(() => import('../views/public/artist/artist'));

const NotesList = React.lazy(() => import('../../components/Notes/NotesList'));
const NoteDetail = React.lazy(() =>
  import('../../components/Notes/NoteDetail')
);

const SectorPage = React.lazy(() =>
  import('../../components/Sectors/SectorPage')
);
const ActivitiesMusic = React.lazy(() =>
  import('../../components/Activities/ActivitiesMusic')
);
const CyclePage = React.lazy(() => import('../../components/Cycle/CyclePage'));

const PortfolioBioPreview = React.lazy(() =>
  import('../../components/PortfolioBioPreview/PortfolioBioPreview')
);
const ProductSingle = React.lazy(() =>
  import('../../components/Products/ProductSingle')
);
const SectionSingle = React.lazy(() =>
  import('../../components/Section/SectionSingle')
);
const Portfolio = React.lazy(() =>
  import('../../components/Portfolio/Portfolio')
);
const PortfolioBio = React.lazy(() =>
  import('../../components/PortfolioBio/PortfolioBio')
);
const PortfolioValidating = React.lazy(() =>
  import('../../components/PortfolioValidating/PortfolioValidating')
);
const SectionValidating = React.lazy(() =>
  import('../../components/SectionValidating/SectionValidating')
);
const SectionPreview = React.lazy(() =>
  import('../../components/SectionPreview/SectionPreview')
);
const SectionStepper = React.lazy(() =>
  import('../../components/SectionStepper/SectionStepper')
);
const Catalog = React.lazy(() => import('../../components/Catalog/Catalog'));
const ProductValidating = React.lazy(() =>
  import('../../components/ProductValidating/ProductValidating')
);
const ProductStepper = React.lazy(() =>
  import('../../components/ProductStepper/ProductStepper')
);
const ServiceValidating = React.lazy(() =>
  import('../../components/ServiceValidating/ServiceValidating')
);
const ServiceStepper = React.lazy(() =>
  import('../../components/ServiceStepper/ServiceStepper')
);
const ResultsPage = React.lazy(() =>
  import('../../components/ResultsPage/ResultsPage')
);
const AccountOptions = React.lazy(() =>
  import('../../components/AccountOptions/AccountOptions')
);
const DeleteAccountStepper = React.lazy(() =>
  import('../../components/AccountOptions/DeleteAccountStepper')
);
const Mensajes = React.lazy(() => import('../views/public/mensajes/Mensajes'));
const Notificaciones = React.lazy(() =>
  import('../views/public/notificaciones/Notificaciones')
);
const Agenda = React.lazy(() => import('../../components/Agenda/Agenda'));
const TurnsTable = React.lazy(() =>
  import('../../components/Turns/TurnsTable')
);
const RoundsFAQ = React.lazy(() =>
  import('../views/public/roundsFAQ/roundsFAQ')
);
const BusinessRound = React.lazy(() =>
  import('../../components/Rounds/BusinessRound')
);

const RoundForm = React.lazy(() => import('../../components/Rounds/RoundForm'));
const ParticipantRound = React.lazy(() =>
  import('../../components/Rounds/ParticipantRound')
);
const Event = React.lazy(() => import('../../components/Event/Event'));
const Activities = React.lazy(() =>
  import('../../components/Activities/Activities')
);
const InvitedRound = React.lazy(() =>
  import('../../components/Rounds/InvitedRound')
);
const SurveyJs = React.lazy(() => import('../../components/Survey/SurveyJs'));
const ProgramPublic = React.lazy(() =>
  import('../../components/Programs/ProgramPublic')
);
const ProgramPointPublic = React.lazy(() =>
  import('../../components/Programs/ProgramPointPublic')
);
const TermsAndConditionsPage = React.lazy(() =>
  import('../../components/TermsAndConditions/TermsAndConditionsPage')
);

export default function PublicLayout(props) {
  const classes = MaterializeCommonStyles();
  const { t } = useTranslation();
  const { location: locationFromProps } = props;
  const { sectors, getSectorDescriptionByKey } = useSectors();
  const shouldHideNavbar = ['/ronda/invitado/'].some((page) =>
    locationFromProps.pathname.startsWith(page)
  );
  const shouldHideFooter = ['/registro', '/ronda/invitado/'].some((page) =>
    locationFromProps.pathname.startsWith(page)
  );
  useTrackingUserSession();

  return (
    <>
      <div id='top-page'></div>
      <MainSkipLinks />
      <div className={classes.rootContentContainer}>
        {!shouldHideNavbar && <Navbar />}
        <main id='main' role='main' tabIndex={-1} aria-label={t('main-label')}>
          <Switch>
            <PublicRoute exact path={`/programa/:friendlyURL`}>
              <ProgramPublic />
            </PublicRoute>
            <PublicRoute exact path={`/programa/:idDomain/:friendlyURL`}>
              <ProgramPointPublic />
            </PublicRoute>
            <PrivateRoute exact path={`/tu-mica`}>
              <YourMICA />
            </PrivateRoute>
            <PrivateRoute exact path={`/tu-mica/rondas/agenda`}>
              <Agenda />
            </PrivateRoute>
            <PrivateRoute exact path={`/tu-mica/rondas/agenda/tab/:tabName`}>
              <Agenda />
            </PrivateRoute>
            <PrivateRoute exact path={`/tu-mica/rondas/agenda/:roundId`}>
              <BusinessRound />
            </PrivateRoute>
            <PrivateRoute exact path={`/tu-mica/rondas/agenda/turnos/:roundId`}>
              <TurnsTable />
            </PrivateRoute>
            <PrivateRoute exact path={`/tu-mica/rondas/preguntas-frecuentes`}>
              <RoundsFAQ />
            </PrivateRoute>
            <PrivateRoute exact path={`/tu-mica/rondas/perfil/negocio`}>
              <RoundForm />
            </PrivateRoute>
            <PrivateRoute exact path={`/tu-mica/portfolio/bio`}>
              <PortfolioBio />
            </PrivateRoute>
            <PublicRoute exact path={`/formulario/:friendlyURL`}>
              <SurveyJs />
            </PublicRoute>
            <PrivateRoute
              exact
              path={`/tu-mica/portfolio/bio/previsualizacion`}
            >
              <PortfolioBioPreview />
            </PrivateRoute>
            <PrivateRoute exact path='/tu-mica/portfolio'>
              <Portfolio />
            </PrivateRoute>
            <PrivateRoute exact path='/tu-mica/portfolio/validacion'>
              <PortfolioValidating />
            </PrivateRoute>
            <PrivateRoute exact path={`/tu-mica/portfolio/seccion/:sectionId?`}>
              <SectionStepper />
            </PrivateRoute>

            <PrivateRoute exact path='/tu-mica/portfolio/seccion-validacion'>
              <SectionValidating />
            </PrivateRoute>
            <PrivateRoute
              exact
              path={`/tu-mica/portfolio/seccion/:sectionId/previsualizacion`}
            >
              <SectionPreview />
            </PrivateRoute>
            <PrivateRoute exact path={`/tu-mica/catalogo`}>
              <Catalog />
            </PrivateRoute>
            <PrivateRoute exact path={`/tu-mica/catalogo/producto/:articleId?`}>
              <ProductStepper />
            </PrivateRoute>

            <PrivateRoute exact path='/tu-mica/portfolio/producto-validacion'>
              <ProductValidating />
            </PrivateRoute>
            <PrivateRoute exact path={`/tu-mica/catalogo/servicio/:articleId?`}>
              <ServiceStepper />
            </PrivateRoute>

            <PrivateRoute exact path='/tu-mica/portfolio/servicio-validacion'>
              <ServiceValidating />
            </PrivateRoute>

            <PublicRoute path={`/inicio`}>
              <Home
                sectors={sectors}
                getSectorDescriptionByKey={getSectorDescriptionByKey}
              />
            </PublicRoute>
            <PublicRoute path={`/terminos-y-condiciones`}>
              <TermsAndConditionsPage />
            </PublicRoute>
            <PublicRoute
              path={`/usuario/:userId/catalogo/articulo/:articleId`}
              exact
            >
              <ProductSingle />
            </PublicRoute>
            <PublicRoute
              path={`/usuario/:userId/portfolio/seccion/:sectionId`}
              exact
            >
              <SectionSingle />
            </PublicRoute>
            <PublicRoute path={`/usuario/:userId/:tabToShow`}>
              <Artist />
            </PublicRoute>
            <PublicRoute path={`/usuario/:userId`}>
              <Artist />
            </PublicRoute>
            <PublicRoute exact path={`/registro/validado/:token`}>
              <RegisterValidated />
            </PublicRoute>
            <PublicRoute exact path={`/registro/validado-correcto`}>
              <RegisterValidatedCorrect />
            </PublicRoute>
            <PublicRoute exact path={`/registro/validado-incorrecto/:email?`}>
              <RegisterValidatedIncorrect />
            </PublicRoute>
            <PublicRoute exact path={`/registro/esperando-validacion/:email?`}>
              <RegisterWaitingValidation />
            </PublicRoute>
            <PublicRoute exact path={`/registro`}>
              <Register />
            </PublicRoute>
            <PublicRoute exact path={`/blanquear-clave/validado-correcto`}>
              <NewPasswordValidatedCorrect />
            </PublicRoute>
            <PublicRoute exact path={`/blanquear-clave/validado/:token`}>
              <NewPassword />
            </PublicRoute>
            <PublicRoute exact path={`/blanquear-clave/:email?`}>
              <ResetPassword />
            </PublicRoute>

            <PublicRoute exact path={`/preguntas-frecuentes`}>
              <FrequentQuestions />
            </PublicRoute>
            <PublicRoute exact path={`/que-es-mica`}>
              <About />
            </PublicRoute>
            <PublicRoute exact path={`/politicas-privacidad`}>
              <PrivacyPolicy />
            </PublicRoute>
            <PublicRoute exact path={`/contacto`}>
              <Contact />
            </PublicRoute>
            <PublicRoute exact path={`/resultados-busqueda/:params?`}>
              <ResultsPage />
            </PublicRoute>

            <PublicRoute exact path={`/notas`}>
              <NotesList />
            </PublicRoute>
            <PublicRoute exact path={`/notas/sector/:sector`}>
              <NotesList />
            </PublicRoute>
            <PublicRoute exact path={`/notas/:friendlyURL`}>
              <NoteDetail />
            </PublicRoute>

            <PublicRoute exact path={`/actividades`}>
              <Activities />
            </PublicRoute>
            <PublicRoute exact path={`/actividades/filters/:sector?`}>
              <Activities />
            </PublicRoute>
            <PublicRoute exact path={`/actividades/musica-argentina-22`}>
              <ActivitiesMusic />
            </PublicRoute>
            <PublicRoute exact path={`/actividades/ciclos/:idCycle`}>
              <CyclePage />
            </PublicRoute>
            <PublicRoute exact path={`/actividades/:activityId`}>
              <Event />
            </PublicRoute>
            <PublicRoute exact path={`/actividades/:activityId/reproducir`}>
              <Event />
            </PublicRoute>
            <PublicRoute
              exact
              path={`/actividades/programa/:idDomain?/punto-de-accion/:idNode?`}
            >
              <Activities />
            </PublicRoute>
            <PublicRoute exact path={`/actividades/programa/:idDomain?`}>
              <Activities />
            </PublicRoute>
            <PublicRoute exact path={`/sector/:sector`}>
              <SectorPage />
            </PublicRoute>
            <PublicRoute
              path={`/actividades/:eventType/:activityId/:roundId`}
              exact
            >
              <BusinessRound isPublic={true} />
            </PublicRoute>

            <PrivateRoute exact path={`/mi-cuenta`}>
              <AccountOptions />
            </PrivateRoute>
            <PrivateRoute exact path={`/mi-cuenta/eliminar-cuenta`}>
              <DeleteAccountStepper />
            </PrivateRoute>
            <PrivateRoute exact path={`/mensajes`}>
              <Mensajes />
            </PrivateRoute>
            <PrivateRoute exact path={`/mensajes/conversacion/:conversationId`}>
              <Mensajes />
            </PrivateRoute>
            <PrivateRoute
              exact
              path={`/mensajes/conversacion/:conversationId/node`}
            >
              <Mensajes />
            </PrivateRoute>
            <PrivateRoute exact path={`/notificaciones`}>
              <Notificaciones />
            </PrivateRoute>

            {/* pasamanos de BBB de un participante sin usuario en MICA (invitado)  */}
            <PublicRoute exact path={`/ronda/invitado/:token`}>
              <InvitedRound />
            </PublicRoute>

            {/* pasamanos de BBB de un usuario de MICA  */}
            <PrivateRoute exact path={`/ronda/participante/:token`}>
              <ParticipantRound />
            </PrivateRoute>

            <PublicRoute exact path={`/cerrar-sesion`}>
              <Logout />
            </PublicRoute>
            <Route exact path='/'>
              <Redirect to={`/inicio`} />
            </Route>
            <PublicRoute path='**' exact>
              <NotFound />
            </PublicRoute>
          </Switch>
        </main>
      </div>
      <LoginModal />
      {!shouldHideFooter && <Footer />}
    </>
  );
}
