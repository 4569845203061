import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Container, Typography } from '@mui/material';
import RelatedProductCard from '../../../../components/Products/RelatedProductCard';
import { ProductBySectorStyles } from '../../../../components/Sectors/ProductBySector/ProductBySectorStyles';
import SlickCarrouselInHome from './SlickCarrouselInHome';
import SkipLink from '../../../../shared/Elements/SkipLinks/SkipLink';
import classNames from 'classnames';

const HomeProducts = ({ articles, isHighlightEnabled }) => {
  const { t } = useTranslation();
  const classes = ProductBySectorStyles();
  const viewMore = `/resultados-busqueda/?query=&type=service,product`;

  if (!articles?.length) {
    return null;
  }

  const list = articles?.map((item) => (
    <div key={item?.id_article} style={{ height: '300px' }}>
      <RelatedProductCard
        product={{
          ...item,
          id_item: item?.id_article,
          id_user: item?.user_id,
          data_type: item?.article_type,
        }}
        borderRadius={true}
      />
    </div>
  ));

  return (
    <section
      id='products'
      aria-label={t('home.carrousels.products-label', {
        size: articles?.length,
      })}
    >
      <Container
        maxWidth={'lg'}
        className={classNames(
          classes.containerProducts,
          classes.containerProductsInHome
        )}
      >
        <div>
          {isHighlightEnabled ? (
            <SkipLink href={`#highlight`}>
              {t('home.carrousels.skip-to-highlight')}
            </SkipLink>
          ) : (
            <SkipLink href={`#activities`}>
              {t('home.carrousels.skip-to-activities')}
            </SkipLink>
          )}

          <div className={classes.productsListHome}>
            <SlickCarrouselInHome name={'productsListHome'} height={'224px'}>
              {list}
            </SlickCarrouselInHome>
          </div>
        </div>
        <div className={classes.containerTitle}>
          <Typography
            variant='h5'
            component={'h5'}
            className={classNames(
              classes.titlePortfolioBySector,
              classes.titlePortfolioInHome
            )}
          >
            {t('home.carrousels.products')}
          </Typography>
          <div className={classes.containerButton}>
            <Link
              className={classes.button}
              to={viewMore}
              aria-label={
                t('sectors.viewMore.more') + ' ' + t('home.carrousels.products')
              }
            >
              {t('sectors.viewMore.more')}
            </Link>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default HomeProducts;
