export const ErrorValues = {
  RETRY_ATTEMPTS: 1,
};

export const BORoles = {
  ADMIN_HOME: 'admin_home',
  ADMIN_SHOWS: 'admin_shows',
  ADMIN_RDN: 'admin_rdn',
  ADMIN_FORMS: 'admin_forms',
  MANAGER_DOMAINS: 'manager_domains',
  RESPONSIBLE_DOMAIN: 'responsible_domain',
  RESPONSIBLE_NODE: 'responsible_node',
  ADMIN_VALIDATOR: 'validador',
  ADMIN_QUERY: 'consulta',
  ADMIN_MANAGER: 'manager',
  SUPER_ADMIN: 'super_admin',
};

export const FalseBORolesForShow = {
  MANAGER_DOMAINS: 'manager',
  RESPONSIBLE_DOMAIN: 'responsible_d',
  RESPONSIBLE_NODE: 'responsible_n',
};

export const CommonGuestRoles = {
  REGULAR: 'regular',
  INVITADO: 'invitado',
  NATIONAL_BUYER: 'comprador_nacional',
  INTERNATIONAL_BUYER: 'comprador_internacional',
};

export const SpecialsRole = {
  SYSTEM: 'sistemas',
  ANONYMOUS: 'anonymous',
};

export const PORTFOLIO_FILTER = {
  NO_PORTFOLIO: 'no_portfolio',
  PORTFOLIO_PUBLISHED: 'portfolio_published',
  PORTFOLIO_HIDDEN: 'portfolio_hidden',
  PORTFOLIO_HIDDEN_ADMIN: 'portfolio_hidden_admin',
  PENDING_ADMIN: 'pending_admin',
};
export const Backoffice = {
  Users: {
    NO_SECTOR: 'null',
  },
  Actions: {
    NEXT: 'next',
    EDIT: 'edit',
    REJECT: 'reject',
    DELETE: 'delete',
    DELETE_PUBLISHED: 'delete_published',
    HIDE: 'hide',
    SHOW: 'show',
    RESTORE: 'restore',
    SUSPEND: 'hide_admin',
  },
  States: {
    CREATED: 'Created',
    DRAFT: 'Draft',
    PENDING: 'Pending',
    VALIDATE_AVAILABLE: 'ValidateAvailable',
    PORTFOLIO_AVAILABLE: 'PortfolioAvailable',
    VALIDATE_NOT_AVAILABLE: 'ValidateNotAvailable',
    PENDING_ADMIN: 'PendingAdmin',
    REJECTED: 'Rejected',
    APPROVED: 'Approved',
    DELETED: 'Deleted',
    DELETED_PUBLISHED: 'DeletedPublished',
    PUBLISHED: 'Published',
    HIDDEN: 'Hidden',
    HIDDEN_PORTFOLIO: 'HiddenPortfolio',
    PRE_APPROVED: 'PreApproved',
    SUSPENDED: 'HiddenAdmin',
    SUSPENDED_PORTFOLIO: 'SuspendedPortfolio',
  },
  StepStates: {
    DRAFT: 0,
    PENDING: 1,
    REJECTED: 0,
    APPROVED: 2,
    DELETED: 1,
    PUBLISHED: 3,
    HIDDEN: 2,
  },
};

export const COLLECTION_STATUS = {
  DRAFT: 'draft',
  PUBLISHED: 'published',
};

export const Forms = {
  Users: {
    GenreOptions: ['Va', 'Mu', 'Tr', 'NB'],
  },
  WorkflowOptions: {
    [COLLECTION_STATUS.DRAFT]: [
      Backoffice.States.DRAFT,
      Backoffice.States.PENDING,
      Backoffice.States.APPROVED,
      Backoffice.States.REJECTED,
      Backoffice.States.PENDING_ADMIN,
    ],
    [COLLECTION_STATUS.PUBLISHED]: [
      Backoffice.States.PUBLISHED,
      Backoffice.States.HIDDEN,
      Backoffice.States.SUSPENDED,
    ],
  },
  WorkflowSectionAndArticlesOptions: {
    [COLLECTION_STATUS.DRAFT]: [
      Backoffice.States.DRAFT,
      Backoffice.States.VALIDATE_AVAILABLE,
      Backoffice.States.PORTFOLIO_AVAILABLE,
      Backoffice.States.VALIDATE_NOT_AVAILABLE,
      Backoffice.States.APPROVED,
      Backoffice.States.REJECTED,
      Backoffice.States.PENDING_ADMIN,
    ],
    [COLLECTION_STATUS.PUBLISHED]: [
      Backoffice.States.PUBLISHED,
      Backoffice.States.HIDDEN,
      Backoffice.States.SUSPENDED,
      Backoffice.States.SUSPENDED_PORTFOLIO,
    ],
  },
  Geo: {
    OTHER: 'other',
  },
  portfolioFiltersOptions: [
    PORTFOLIO_FILTER.NO_PORTFOLIO,
    PORTFOLIO_FILTER.PORTFOLIO_PUBLISHED,
    PORTFOLIO_FILTER.PORTFOLIO_HIDDEN,
    PORTFOLIO_FILTER.PORTFOLIO_HIDDEN_ADMIN,
    PORTFOLIO_FILTER.PENDING_ADMIN,
  ],
};
export const ErrorCodes = {
  TOKEN_EXPIRED: 'token-expired',
  TOKEN_INVALID: 'token-invalid',
  NETWORK_ERROR: 'network-error',
  LOGOUT: 'logout',
};

export const SURVEY = {
  ANSWER_MODE: {
    UNIQUE: 'unique',
    MULTIPLE: 'multiple',
    OVERWRITES: 'overwrites',
  },
  STATE: {
    ACTIVE: 'active',
    PASSED: 'passed',
    SOON: 'soon',
  },
};

export const CONTENT_TYPES = {
  IMAGE: 'image',
  AUDIO: 'audio',
  SOUNDCLOUD_URL: 'soundcloud_url',
  YOUTUBE_URL: 'youtube_url',
  VIDEO: 'video',
  TEXT: 'text/plain',
  COVER: 'cover',
  YOUTUBE: 'youtube',
  VIMEO: 'vimeo',
  OTHER: 'other',
};

export const SUPPORTED_IMAGE_FORMATS = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'image/bnp',
  'image/svg',
];

export const SUPPORTED_VIDEO_FORMATS = [
  'video/mp4',
  'video/avi',
  'video/webm',
  'video/ogg',
  'video/mkv',
  'video/flv',
  'video/mov',
  'video/wmv',
  'video/mpg',
];

export const SUPPORTED_AUDIO_FORMATS = [
  'audio/mp3',
  'audio/mp4',
  'audio/wma ',
  'audio/wav',
  'audio/ogg',
  'audio/mpeg',
];

export const MAX_AUDIO_FILE_SIZE = 20 * 1024 * 1024; //bytes 20 MB: Megabyte
export const MAX_IMAGE_FILE_SIZE = 50 * 1024 * 1024; //bytes 50 MB: Megabytes
export const MAX_VIDEO_FILE_SIZE = 50 * 1024 * 1024; //bytes 50 MB: Megabytes

export const ArticleType = {
  PRODUCT: 'product',
  SERVICE: 'service',
};

export const ArticlePriceType = {
  CONSULT: 'consult',
  PRICE: 'price',
  OFFER: 'offer',
};

export const ArticleStock = {
  YES: 'yes',
  NO: 'no',
  ORDER: 'order',
};

export const ArticleStockType = {
  ONLY: 'only',
  LIMITED: 'limited',
  NONE: 'none',
};

export const ArticleTypeFilter = [
  {
    label: 'my-mica.portfolio.filters.types.values.products',
    value: ArticleType.PRODUCT,
  },
  {
    label: 'my-mica.portfolio.filters.types.values.services',
    value: ArticleType.SERVICE,
  },
];
export const OthersCategoryType = {
  SECTOR: 'sector',
  CYCLE: 'cycle',
  DOMAIN: 'domain',
  NODE: 'node',
  PAGE: 'page',
};

export const NotificationType = {
  EVENT: 'event', //noti de invitacion a ronda
  BULK_NOTIFICATION: 'bulk_notification',
  NEXT_MEETING: 'next_meeting',
  FOLLOW: 'follow',
  TIP: 'tip',
  PUBLISHED_ITEM: 'published_item',
  FORM_EVENT: 'form_event',
  UNOPENED: 'unopened',
  NOTICE: 'notice', // notificacion de nuevo mensaje en la ronda y cuando hay un cambio en la ronda
  NOTICE_STATUS: 'notice_status', //notificacion de cambio de estado del participante
  HIDDEN_TIP: 'hidden_tip',
};

export const NotificationClassType = {
  ROUND: 'round',
  MEETING: 'meeting',
};

export const EntitiesType = {
  PORTFOLIO: 'portfolio',
  SECTION: 'section',
  ARTICLE: 'article',
  ACTIVITY: 'activity',
  NOTE: 'novelty',
  PUBLIC_ROUND: 'public_round',
};

export const EventsType = {
  ACTIVITY: 'actividad',
  ROUND: 'ronda',
  EVENT: 'event',
  SHOW: 'streaming',
  CYCLE: 'cycle',
};

export const SearchEntitiesType = {
  ALL: '',
  PORTFOLIOS: 'perfil',
  PRODUCTS: 'product',
  SERVICES: 'service',
  SECTIONS: 'portfolio',
  ACTIVITIES: 'activity',
  NOTES: 'novelty',
  SECTOR: 'sector',
  CYCLE: 'cycle',
  DOMAIN: 'domain',
  PAGE: 'page',
  NODE: 'node',
  OTHERS: 'others',
};

export const SORT_BY = {
  RELEVANT: 'score',
  LAST: 'date_created',
};

export const PRICE_FILTERS = {
  ANY: 'price',
  ASK: 'consult',
  BETWEEN: 'between',
};

export const PRICE_OPTIONS = {
  CONSULT: 'consult',
  PRICE: 'price',
};

export const ARTICLE_TYPE = {
  PORTFOLIOS: 'portfolios',
  PRODUCTS: 'products',
  SERVICES: 'services',
  SECTIONS: 'sections',
};

export const SEARCH_PAGE_SIZE = 12;
export const SEARCH_PAGE_SIZE_MOBILE = 3;

export const IntervalTimes = {
  UNREAD: process.env.REACT_APP_INTERVAL_UNREAD,
  NAVBAR_NOTIFICATION: process.env.REACT_APP_INTERVAL_NOTIFICATION,
  NAVBAR_CHAT: process.env.REACT_APP_INTERVAL_MESSAGE,
  NOTIFICATION: 30_000,
  CHAT: 30_000,
  SLIDER: 12_000,
  BO_SLIDER_STATUS: 30_000,
  MASSIVE_NOTIFICATION_LIST: 30_000,
  MASSIVE_NOTIFICATION_DETAIL: 15_000,
  BO_DASHBOARD: 30_000,
  ABOUT_IMAGES: 4_000,
  TRACK_PLAYER: 30_000,
};

export const EventsInterested = [
  { value: 'yes', label: 'Si' },
  { value: 'no', label: 'No' },
  { value: 'no_answer', label: 'Sin respuesta' },
];

export const BORowsPerPageOptions = [10, 20, 100, 500, 1_000, 2_000];
export const BORowsPerPageOptionsStats = [10, 50, 100, 1_000, 2_000];

export const EVENT_TYPE_CLASS = {
  ROUND: 'round',
  MEETING: 'meeting',
  DOMAIN: 'domain',
  SHOW: 'show',
  NOVELTY: 'novelty',
};

export const EVENT_TYPE_ROUTE_PARAM = {
  rondas: EVENT_TYPE_CLASS.ROUND,
  encuentros: EVENT_TYPE_CLASS.MEETING,
  'encuentros-de-programas': EVENT_TYPE_CLASS.DOMAIN,
};
export const MOMENT_FILTER = {
  ACTIVE: 'active',
  COMING: 'coming',
  PASSED: 'passed',
};

export const countryDefault = 'ARG';

export const SESSION_WINDOW = {
  SESSION: 'session',
  FREQUENCY: 'frequency',
  DURATION: 'duration',
  COUNT_PAGE_VISITED: 'count_page_visited',
};

export const TABS_USE_SITE = {
  SESSION: '/administracion/estadistica/uso/tab/session',
  FREQUENCY: '/administracion/estadistica/uso/tab/frequency',
  DURATION: '/administracion/estadistica/uso/tab/duration',
  COUNT_PAGE_VISITED: '/administracion/estadistica/uso/tab/count_page_visited',
  EVENTS: '/administracion/estadistica/uso/tab/events',
  REFERER: '/administracion/estadistica/uso/tab/referer',
  ACCESS_AND_EGRESS: '/administracion/estadistica/uso/tab/access_and_egress',
};
export const USE_SITE_TABS_INDEX = {
  SESSION: 0,
  FREQUENCY: 1,
  DURATION: 2,
  COUNT_PAGE_VISITED: 3,
  EVENTS: 4,
  REFERER: 5,
  ACCESS_AND_EGRESS: 6,
};
export const USE_SITE_TABS = {
  SESSION: 'session',
  FREQUENCY: 'frequency',
  DURATION: 'duration',
  COUNT_PAGE_VISITED: 'count_page_visited',
  EVENTS: 'events',
  REFERER: 'referer',
  ACCESS_AND_EGRESS: 'access_and_egress',
};

export const TABS_ACCOUNT_AND_CONTENT = {
  RECORDS: '/administracion/estadistica/usuarios/tab/records',
  PORTFOLIO: '/administracion/estadistica/usuarios/tab/portfolio',
  SECTION: '/administracion/estadistica/usuarios/tab/section',
  PRODUCT: '/administracion/estadistica/usuarios/tab/product',
  SERVICE: '/administracion/estadistica/usuarios/tab/service',
};
export const ACCOUNT_AND_CONTENT_TABS_INDEX = {
  RECORDS: 0,
  PORTFOLIO: 1,
  SECTION: 2,
  PRODUCT: 3,
  SERVICE: 4,
};
export const ACCOUNT_AND_CONTENT_TABSNAME = {
  RECORDS: 'records',
  PORTFOLIO: 'portfolio',
  SECTION: 'section',
  PRODUCT: 'product',
  SERVICE: 'service',
};

export const USE_SITE_GRAPHS_LABEL = {
  SESSION: 'session',
  FREQUENCY: 'frequency',
  DURATION: 'avg_duration',
  COUNT_PAGE_VISITED: 'avg_pages',
  RECORDS: 'records',
};

export const EVENT_TYPE_BO_ROUTE = {
  round: 'ronda-negocios',
  meeting: 'encuentros',
};

export const TEXT_INVITE_MAX_LENGTH = {
  ROUND: 1500,
  MEETING: 1200,
};

export const EVENT_TYPE_MEETING = {
  TALK: 'talk',
  CONVERSATION: 'conversation',
  DISCUSSION_TABLE: 'discussion_table',
  INSTITUTIONAL_TABLE: 'institutional_table',
  MEETING: 'meeting',
  BUSINESS_ROUND: 'business_round',
  TRAINING: 'training',
  WORKSHOP: 'workshop',
};

export const TYPE_SECTORS_EVENT = {
  GENERAL: 'general',
  SPECIFIC: 'specific',
};

export const CYCLES = {
  EMPTY: 'no_cycle',
};

export const TOPICS = {
  EMPTY: 'no_topic',
};

export const TYPE_USERS_EVENT = {
  USER_INDISTINCT: 'user_indistinct',
  USER_REGISTER: 'user_register',
  USER_NOT_REGISTER: 'user_not_register',
  USER_PORTFOLIO: 'user_portfolio',
  USER_NOT_PORTFOLIO: 'user_not_portfolio',
  USER_PORTFOLIO_AND_BUSINESS_PROFILE: 'user_portfolio_and_business_profile',
};

export const AppointmentsStates = {
  FREE: 'free',
  LOCKED: 'locked',
  ASSIGNED: 'assigned',
};

export const ParticipantsStates = {
  CONFIRMED: 'confirmed',
  PRE_SELECTED: 'pre_selected',
};

export const Priorities = {
  HIGH: 'high',
  NORMAL: 'medium',
  LOW: 'low',
  NONE: 'no_priority',
  EXCLUDE: 'exclude',
};

export const RoundsRoles = {
  MODERATOR: 'moderator',
  SELLER: 'seller',
  PURCHASER: 'purchaser',
};

export const HostRoles = [RoundsRoles.MODERATOR, RoundsRoles.PURCHASER];

export const RoundsNotifyTypes = {
  MODERATOR: 'moderator',
  PURCHASER: 'purchaser',
  SELLER: 'seller',
  INTERESTED: 'interested',
  PRESELECTED: 'pre_selected',
  WAITING_LIST: 'waiting_list',
  CONFIRMED: 'confirmed',
};

export const RoundsStatus = {
  ACTIVE: 'active',
  CANCELLED: 'cancelled',
  DELETED: 'deleted',
  DONE: 'done',
};

export const SURVEY_STATUS = {
  ACTIVE: 'active',
  PASSED: 'passed',
  SOON: 'soon',
};

export const SURVEY_VISIBLE = {
  TRUE: 'true',
  FALSE: 'false',
};

export const RoundsLocation = {
  ANY: 'any',
  OTHER: 'other',
  VIRTUAL: 'virtual_mica',
};

export const RoundsParticipantStatus = {
  NOT_INVITED: 'not_invited',
  PENDING: 'pending_answer',
  NOT_INTERESTED: 'not_interested',
  INTERESTED: 'interested',
  REJECTED: 'rejected',
  PRE_SELECTED: 'pre_selected',
  CONFIRMED: 'confirmed',
  NO_VACANCY: 'no_vacancy',
  WAITING_LIST: 'waiting_list',
  CANCELLED: 'event_cancelled',
};

export const HistoricRoundsParticipantStatus = {
  PAST_PARTICIPATED: 'past_participated',
  PAST_NOT_PARTICIPATED: 'past_not_participated',
  PAST_REJECTED: 'rejected',
};

export const ActionTypes = {
  DELETE: 'delete',
  INVITE: 'invite',
};

export const MessagesStatus = {
  ARCHIVED: 'archived',
  EMAIL: 'email',
};

export const ROUND_ERRORS = {
  USER_DONT_HAVE_PORTFOLIO: 'user-dont-have-portfolio',
  EVENT_REGISTRATION_DEADLINE_END: 'event-registration-deadline-end',
  USER_SECTOR_NOT_IN_ROUND_SECTOR: 'user-sector-not-in-round-sector',
  USER_DONT_HAVE_PORTFOLIO_AND_BUSINESS_PROFILE:
    'user-dont-have-portfolio-and-business-profile',
  USER_DONT_HAVE_BUSINESS_PROFILE: 'user-dont-have-business-profile',
};

export const MEET_ERRORS = {
  BBB_BAD_TOKEN: 'bbb-bad-token',
  EVENT_NOT_EXISTS: 'event-not-exists',
  USER_PARTICIPANT_NOT_CONFIRMED: 'user-participant-not-confirmed',
  USER_PARTICIPANT_NOT_ACTIVE: 'user-participant-not-active',
  BBB_TOKEN_NOT_CURRENT_USER: 'bbb-token-not-current-user',
  USER_NOT_PARTICIPANT_OF_EVENT: 'user-not-participant-of-event',
  EVENT_TOO_EARLY_TO_JOIN: 'event-too-early-to-join',
  EVENT_INCOMPLETE: 'event-incomplete',
  BBB_UNEXPECTED_ERROR: 'bbb-unexpected-error',
  PARTICIPANT_EXISTS: 'participants-exist-in-event',
};

export const SURVEY_ERRORS = {
  SURVEY_NOT_EXISTS: 'survey-not-exists',
  DELETED_SURVEY: 'deleted-survey',
};

export const SHOW_ERRORS = {
  NOT_AVAILABLE: 'activity-not-available-yet',
};

export const MEET_CONFIG = {
  JOIN_MINUTES_BEFORE: 120,
};

export const FREQUENCY_OPTIONS = {
  INDIVIDUAL: 'individual',
  // DAILY: 'daily',
  // WEEKLE: 'weekly',
};

export const MAILING_OPTIONS = {
  NOTIFICATIONS: 'notifications',
  MESSAGES: 'messages',
  // REQUESTS: 'requests',
  // INTEREST_NEWS: 'interest-news',
  // PORTFOLIO_NEWS: 'portfolio-news',
  // MEETINGS: 'meetings',
  // SECTOR_NEWS: 'sector-news',
  // FOLLOWERS: 'followers',
};

//ver https://mui.com/customization/z-index/
export const Z_INDEX = {
  MINIMUM: 0,
  LOWER: 1,
  MEDIUM: 2,
  HIGH: 3,
};

export const BACKOFFICE_ROUTES = {
  PORTFOLIOS_DRAFT: '/validacion/portfolios',
  SECTIONS_DRAFT: '/validacion/secciones',
  ARTICLES_DRAFT: '/validacion/articulos',
  PORTFOLIOS_PUBLISHED: '/publico/portfolios',
  SECTIONS_PUBLISHED: '/publico/secciones',
  ARTICLES_PUBLISHED: '/publico/articulos',
};

export const ScrollTo = {
  SEARCH_POS: 'scrollPositionSearch',
  IS_SEARCH: 'isSearch',
};

export const CABA = {
  SHORT: 'CABA',
  LONG: 'Ciudad Autónoma de Buenos Aires',
};

export const IMAGE_SIZE = {
  RESPONSIVE: 'responsive',
  THUMBNAIL: 'thumbnail',
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
  ORIGINAL: 'original',
};

export const IMAGE_SIZES_SORTED = [
  'thumbnail',
  'small',
  'medium',
  'large',
  'webp',
  'original',
];

export const IMAGE_LOADING = {
  LAZY: 'lazy',
  EAGER: 'eager',
  AUTO: 'auto',
};

export const IMAGE_PRIORITY = {
  HIGH: 'high',
  LOW: 'low',
  AUTO: 'auto',
};

export const HIGHLIGHT_IMAGE = {
  DEFAULT: {
    HEIGHT: 'auto',
    WIDTH: '100%',
  },
  UP_SM: {
    HEIGHT: 188,
    WIDTH: 334,
  },
  UP_MD: {
    HEIGHT: 253,
    WIDTH: 450,
  },
  UP_LG: {
    HEIGHT: 324,
    WIDTH: 576,
  },
};

export const DATE_FORMAT = {
  STANDARD: 'DD/MM/YYYY',
  STANDARD_SHORT_YEAR: 'DD/MM/YY',
  STANDARD_WITHOUT_YEAR: 'DD/MM',
  STANDARD_TIME: 'DD/MM/YYYY - HH:mm',
  STANDARD_TIME_WITHOUT_YEAR: 'DD/MM - HH:mm',
  STANDARD_TIME_WITH_DAY: 'dddd DD/MM/YYYY - HH:mm',
  READABLE_DAY: 'DD/MM dddd',
  READABLE_DAY_WITH_READABLE_MONTH: 'dddd DD MMMM',
  READABLE_DAY_WITH_READABLE_DAY: 'dddd DD/MM',
  READABLE_MONTH: 'DD MMMM YYYY',
  READABLE_MONTH_WITH_YEAR: 'MMMM YYYY',
  TIME: 'HH:mm',
  TIME_WITH_DOTS: 'H.mm',
  MONTH: 'mm',
  YEAR: 'YYYY',
  ADDTOCALENDAR: 'MM-DD-YYYY',
};

export const CREATE_EVENT_IMAGES_DEFAULT = {
  ROUND: `${process.env.PUBLIC_URL}/BO/round-default-image.jpg`,
  MEETING: `${process.env.PUBLIC_URL}/BO/meeting-default-image.jpg`,
  DOMAIN: `${process.env.PUBLIC_URL}/BO/domain-meeting-default-image.jpg`,
};

export const CREATE_EVENT_IMAGES_DEFAULT_SIZE = {
  WIDTH: '1032',
  HEIGHT: '387',
};

export const SHOW_STATUS = {
  NOT_AVAILABLE: 'not_available_yet',
  COMING_SOON: 'coming_soon',
  WATCH_NOW: 'watch_now',
  WATCH_EXTERNAL: 'watch_external',
  PAST: 'past',
};

export const SHOW_VISIBILITY = {
  VISIBLE: 'visible',
  HIDDEN: 'hidden',
};

export const SHOW_EXTERNAL_LINK = {
  HAS: 'yes',
  DOESNT_HAVE: 'no',
};

export const ACTIVITY_DATE_FILTERS = {
  ANY: 'any',
  TODAY: 'today',
  TOMORROW: 'tomorrow',
  THIS_WEEK: 'this_week',
  NEXT_WEEK: 'next_week',
  THIS_MONTH: 'this_month',
  NEXT_MONTH: 'next_month',
};

export const ACTIVITY_TYPE_FILTERS = {
  DOMAIN: 'domain',
  MEETING: 'meeting',
  SHOW: 'streaming',
  TALK: 'talk',
  CONVERSATION: 'conversation',
  DISCUSSION_TABLE: 'discussion_table',
  INSTITUTIONAL_TABLE: 'institutional_table',
  NOVELTY: 'novelty',
  STREAMING: 'streaming',
  PUBLIC_ROUND: 'public_round',
  CYCLE: 'cycle',
  CONTEST: 'contest',
  CALL: 'call',
  TRAINING: 'training',
  WORKSHOP: 'workshop',
};

export const SLIDER_COLORS = {
  FUCHSIA: '#FF2470',
  BLUE: '#1C6BFA',
  GREEN: '#BDFA29',
  RED: '#E3313D',
  BEIGE: '#948073',
  ORANGE: '#FC5945',
  PINK: '#FAABCC',
  WHITE: '#EFEFEF',
  BLACK: '#000000',
};
export const MULTI_FEATURED_COLOR = {
  FUCHSIA: '#FF2470',
  BLUE: '#1C6BFA',
  GREEN: '#BDFA29',
  BEIGE: '#948073',
  ORANGE: '#FC5945',
  PINK: '#FAABCC',
  RED: '#E3313D',
  BLACK: '#000000',
};
export const SLIDER_COLORS_TEXT = {
  FUCHSIA: 'black',
  BLUE: 'black',
  GREEN: 'black',
  RED: 'black',
  BEIGE: 'black',
  ORANGE: 'black',
  PINK: 'black',
  WHITE: 'black',
  BLACK: 'white',
};

export const SLIDER_COLORS_DEFAULT_KEY = 'RED';
export const SLIDER_COLORS_DEFAULT_KEY_FEATURED = 'ORANGE';
export const SLIDER_COLORS_DEFAULT_EXA = SLIDER_COLORS.RED;
export const SLIDER_COLORS_TEXT_DEFAULT = SLIDER_COLORS_TEXT[SLIDER_COLORS.RED];

export const CYCLE_FILTERS = {
  MICA_2022: 'mica_2022',
};

export const INTEREST_ROUNDS = {
  ALL: 'all',
  NOT_INTERESTED: 'not_interested',
  NATIONAL: 'national',
  INTERNATIONAL: 'international',
  NATIONAL_AND_INTERNATIONAL: 'national_and_international',
  NO_INFORMATION: 'no_information',
};

export const SECTORS_FILTERS = {
  general: TYPE_SECTORS_EVENT.GENERAL,
  artes_visuales: 'artes_visuales',
  artesanias: 'artesanias',
  arte_infancias: 'arte_infancias',
  audiovisual: 'audiovisual',
  circo: 'circo',
  danza: 'danza',
  disenio: 'disenio',
  editorial: 'editorial',
  folklore: 'folklore',
  gastronomia: 'gastronomia',
  hip_hop: 'hip_hop',
  musica: 'musica',
  musica_academica: 'musica_academica',
  tango: 'tango',
  teatro: 'teatro',
  videojuegos: 'videojuegos',
};

export const SECTOR_BACKEND_TAGS_TRANSLATION = {
  artes_visuales: 'Artes visuales',
  artesanias: 'Artesanias',
  arte_infancias: 'Infancias',
  audiovisual: 'Audiovisual',
  circo: 'Circo',
  danza: 'Danza',
  disenio: 'Diseño',
  editorial: 'Editorial',
  folklore: 'Folklore',
  gastronomia: 'Gastronomía',
  hip_hop: 'Hip Hop',
  musica: 'Música',
  musica_academica: 'Música académica',
  tango: 'Tango',
  teatro: 'Teatro',
  videojuegos: 'Videojuegos',
};

export const SECTOR_PAGES = {
  artes_visuales: 'Artes visuales',
  audiovisual: 'Audiovisual',
  circo: 'Circo',
  danza: 'Danza',
  disenio: 'Diseño',
  editorial: 'Editorial',
  folklore: 'Folklore',
  gastronomia: 'Gastronomía',
  hip_hop: 'Hip Hop',
  arte_infancias: 'Infancias',
  musica: 'Música',
  musica_academica: 'Música académica',
  tango: 'Tango',
  teatro: 'Teatro',
  videojuegos: 'Videojuegos',
};

export const SECTOR_COLORS = {
  artes_visuales: '#f7a9cb',
  audiovisual: '#ff2471',
  circo: '#fd4e45',
  danza: '#bdfb28',
  disenio: '#f7a9cb',
  editorial: '#fd4e45',
  folklore: '#bdfb28',
  gastronomia: '#ff2471',
  hip_hop: '#fd4e45',
  arte_infancias: '#bdfb28',
  musica: '#f7a9cb',
  musica_academica: '#ff2471',
  tango: '#bdfb28',
  teatro: '#fd4e45',
  videojuegos: '#f7a9cb',
};

export const PRIMARY_SECTOR = {
  ALL: 'all',
  VISUAL_ART: 'visual_art',
  HANDICRAFT: 'handicraft',
  AUDIOVISUAL: 'audiovisual',
  CIRCUS: 'circus',
  DANCE: 'dance',
  DESIGN: 'design',
  EDITORIAL: 'editorial',
  FOLKLORE: 'folklore',
  GASTRONOMY: 'gastronomy',
  HIP_HOP: 'hip_hop',
  CHILDHOOD: 'childhood',
  MUSIC: 'music',
  ACADEMIC_MUSIC: 'academic_music',
  TANGO: 'tango',
  THEATER: 'theater',
  VIDEOGAMES: 'videogames',
  NO_INFORMATION: 'no_information',
};

export const SECONDARY_SECTOR = {
  ALL: 'all',
  VISUAL_ART: 'visual_art',
  HANDICRAFT: 'handicraft',
  AUDIOVISUAL: 'audiovisual',
  CIRCUS: 'circus',
  DANCE: 'dance',
  DESIGN: 'design',
  EDITORIAL: 'editorial',
  FOLKLORE: 'folklore',
  GASTRONOMY: 'gastronomy',
  HIP_HOP: 'hip_hop',
  CHILDHOOD: 'childhood',
  MUSIC: 'music',
  ACADEMIC_MUSIC: 'academic_music',
  TANGO: 'tango',
  THEATER: 'theater',
  VIDEOGAMES: 'videogames',
  NO_INFORMATION: 'no_information',
};

export const SECTORS = {
  VISUAL_ART: 'artes_visuales',
  HANDICRAFT: 'artesanias',
  AUDIOVISUAL: 'audiovisual',
  CIRCUS: 'circo',
  DANCE: 'danza',
  DESIGN: 'disenio',
  EDITORIAL: 'editorial',
  FOLKLORE: 'folklore',
  GASTRONOMY: 'gastronomia',
  HIP_HOP: 'hip_hop',
  CHILDHOOD: 'arte_infancias',
  MUSIC: 'musica',
  ACADEMIC_MUSIC: 'musica_academica',
  TANGO: 'tango',
  THEATER: 'teatro',
  VIDEOGAMES: 'videojuegos',
};

export const ROUNDS_PARTICIPATED = {
  ALL: 'all',
  YES: 'yes',
  NO: 'no',
};

export const ROUNDS_INTERESTED_STATES = {
  YES: 'yes',
  NO: 'no',
  YES_INCOMPLETE: 'yesIncomplete',
};

export const INTERNATIONAL_PARTICIPATIONS = {
  ALL: 'all',
  YES: 'yes',
  NO: 'no',
};

export const MICA_PREVIOUS_PARTICIPATIONS = {
  ALL: 'all',
  NO: 'no',
  NATIONAL: 'national',
  REGIONAL: 'regional',
  NATIONAL_AND_REGIONAL: 'national_and_regional',
  MICSUR_2014: 'micsur_2014',
  MICSUR_2016: 'micsur_2016',
  MICBR_2018: 'micbr_2018',
  NO_INFORMATION: 'no_information',
};

export const EXPORTED_EXPERIENCE = {
  ALL: 'all',
  EMERGING_EXPORTER: 'emerging_exporter',
  LITTLE_EXPORTER: 'little_exporter',
  CONSOLIDATED_EXPORTER: 'consolidated_exporter',
  NO_INFORMATION: 'no_information',
};

export const CONSOLIDATED_EXPORTABLE_OFFER = {
  ALL: 'all',
  YES: 'yes',
  NO: 'no',
  NO_INFORMATION: 'no_information',
};

export const PORTFOLIO_BUSINESS_PROFILE = {
  ALL: 'all',
  NO_PORTFOLIO: 'no_portfolio',
  HAS_PORTFOLIO: 'has_portfolio',
  PORTFOLIO_AND_BUSINESS: 'portfolio_and_business',
};

export const SOCIAL_NETWORKS = {
  INSTAGRAM: 'https://www.instagram.com/mica.cultura/',
  TWITTER: 'https://twitter.com/micacultura',
  FACEBOOK: 'https://www.facebook.com/MICAcultura/',
  TWITCH: 'https://www.twitch.tv/micacultura',
};

export const NOVELTY_TYPE = {
  NOVELTY: 'novelty',
  CALL: 'call',
  CONTEST: 'contest',
  TRAINING: 'training',
  NOTE: 'note',
  NOTE_LINK: 'link',
};

export const NOVELTY_TYPE_SELECT = {
  NOVELTY: 'novelty',
  CALL: 'call',
  CONTEST: 'contest',
  TRAINING: 'training',
};

export const NOVELTY_STATUS = {
  CURRENT: 'active',
  PAST: 'past',
  FUTURE: 'future',
  TIMELESS: 'timeless',
};

export const NOVELTIES_AND_NOTES = {
  TYPE: {
    NOTE: 'note',
  },
  ROUTE: {
    NOTES: '/notas/',
    NOVELTIES: '/administracion/novedades/',
  },
};

export const TYPE_DATE_TO_SHOW = {
  EMPTY: 'not_show',
  MONTH: 'month_format',
  DATE: 'date_format',
};
export const TYPE_GROUPER_DATE = {
  DAY: 'day',
  MONTH: 'month',
  YEAR: 'year',
};

export const PARTICIPANT_SELECTION = {
  ALL: 'all',
  NOT_INVITED: 'not_invited',
  PENDING: 'pending',
  NOT_INTERESTED: 'not_interested',
  INTERESTED: 'interested',
  REJECTED: 'rejected',
  PRESELECTED: 'pre_selected',
  WAITING_LIST: 'waiting_list',
  CONFIRMED: 'confirmed',
};
export const PARTICIPANT_PROCESSING_STATE = {
  STAND_BY: 0,
  LOADING: 1,
  SUCCESS: 2,
  ERROR: 3,
};

export const PROGRAM_LOGO_MEASURES = {
  width: 600,
};

export const HIGHLIGHT_MEASURES = {
  width: 576,
  height: 324,
};

export const PROGRAM_HIGHLIGHT_MEASURES = {
  width: 840,
  height: 472,
};

export const SLIDER_MEASURES = {
  //recomiendan el aspect ratio 3:1 para sliders y 1500px por 500px https://calculateaspectratio.com/
  width: 1200,
  height: 600,
};

export const MASSIVE_NOTIFICATION_MEASURES = {
  width: 600,
  height: 150,
};

export const ICON_MEASURES = {
  width: 32,
  height: 32,
};

export const AUDIOVISUAL_CONTENT_TYPES = {
  PHOTO: 'photo',
  VIDEO: 'video',
};

export const VIDEO_ORIGINS = {
  LOCAL: 'local',
  EXTERNAL_URL: 'transcoded',
};

export const VIDEO_ORIGINS_PROGRAM = {
  LOCAL: 'local',
  EXTERNAL_URL: 'transcoded',
  NULL: null,
};

export const SLIDER_CONFIG = {
  MIN: 2,
  MAX: 12,
};

export const MULTI_FEATURED = {
  MIN: 2,
  MAX: 7,
};

export const SLIDER_ACTIONS_TYPE = {
  EDIT: 'edit',
  PUBLISH: 'publish',
};

export const SLIDER_LINK_TARGET = {
  YES: '_blank',
  FALSE: '_self',
};

export const LOGIN_ERRORS = {
  NOT_CONFIRMED: 'login-email-not-confirmed',
  INACTIVE_USER: 'user-not-active',
  LOGIN_USER_NOT_EXISTS: 'login-user-not-exists',
};

export const MASSIVE_NOTIFICATION_SEND_STATUS = {
  DRAFT: 'draft',
  PROGRAMMED: 'to_send',
  SENDING: 'sending',
  SHIPPING_PAUSED: 'shipping_paused',
  SHIPPING_INCOMPLETE: 'shipping_incomplete',
  SENT: 'sent',
  ACTIVE: 'active',
  ARCHIVED: 'archived',
  DELETED: 'deleted',
};

export const MASSIVE_NOTIFICATION_STATE = {
  DRAFT: 'draft',
  ACTIVE: 'active',
  ARCHIVED: 'archived',
  DELETED: 'deleted',
  STOP_SHIPPING: 'stop_shipping',
};

export const MASSIVE_NOTIFICATION_SENDING_STATES = {
  SEND: 'send',
  RESUME_SHIPPING: 'resume_shipping ',
  RETRY_SENDING: 'retry_sending ',
};

export const MASSIVE_NOTIFICATION_RECIPIENT_STATE = {
  SENT: 'sent',
  PAUSED: 'paused',
  FAILED: 'failed',
};

export const PROGRAM_POINT_STATE = {
  PUBLISHED: 'published',
  HIDDEN: 'hidden',
  HIDDEN_BY_PROGRAM: 'hidden_by_program',
};

export const statusMeaning = {
  100: 'Continue',
  101: 'Switching protocols',
  102: 'Processing',
  103: 'Early Hints',
  200: 'OK',
  201: 'Created',
  202: 'Accepted',
  203: 'Non-Authoritative Information',
  204: 'No Content',
  205: 'Reset Content',
  206: 'Partial Content',
  207: 'Multi-Status',
  208: 'Already Reported',
  226: 'IM Used',
  300: 'Multiple Choices',
  301: 'Moved Permanently',
  302: 'Found (Previously "Moved Temporarily")',
  303: 'See Other',
  304: 'Not Modified',
  305: 'Use Proxy',
  306: 'Switch Proxy',
  307: 'Temporary Redirect',
  308: 'Permanent Redirect',
  400: 'Bad Request',
  401: 'Unauthorized',
  402: 'Payment Required',
  403: 'Forbidden',
  404: 'Not Found',
  405: 'Method Not Allowed',
  406: 'Not Acceptable',
  407: 'Proxy Authentication Required',
  408: 'Request Timeout',
  409: 'Conflict',
  410: 'Gone',
  411: 'Length Required',
  412: 'Precondition Failed',
  413: 'Payload Too Large',
  414: 'URI Too Long',
  415: 'Unsupported Media Type',
  416: 'Range Not Satisfiable',
  417: 'Expectation Failed',
  418: 'I"m a Teapot',
  421: 'Misdirected Request',
  422: 'Unprocessable Entity',
  423: 'Locked',
  424: 'Failed Dependency',
  425: 'Too Early',
  426: 'Upgrade Required',
  428: 'Precondition Required',
  429: 'Too Many Requests',
  431: 'Request Header Fields Too Large',
  451: 'Unavailable For Legal Reasons',
  500: 'Internal Server Error',
  501: 'Not Implemented',
  502: 'Bad Gateway',
  503: 'Service Unavailable',
  504: 'Gateway Timeout',
  505: 'HTTP Version Not Supported',
  506: 'Variant Also Negotiates',
  507: 'Insufficient Storage',
  508: 'Loop Detected',
  510: 'Not Extended',
  511: 'Network Authentication Required',
};

export const ROUNDS_BOOL = {
  YES: 'yes',
  NO: 'no',
};

export const APPOINTMENT_OPTIONS = {
  false: false,
  true: true,
};

export const SHIFT_APPOINTMENT_DURATIONS = {
  5: '5',
  10: '10',
  15: '15',
  20: '20',
  30: '30',
  45: '45',
  60: '60',
  90: '90',
};

export const RECCESS_APPOINTMENT_DURATIONS = {
  0: '0',
  5: '5',
  10: '10',
  15: '15',
};

export const PUBLIC_ROUND = 'public_round';

export const TURN_ACTION = {
  ADMIN_CANCEL: 'admin_cancel',
  ASSIGN_TURN: 'assign_turn',
  PARTICIPANT_CANCEL: 'participant_cancel',
  LOCK_TURN: 'lock_turn',
  UNLOCK_TURN: 'unlock_turn',
  CONFIRM_TURN: 'confirm_turn',
};

export const TURN_ERROR = {
  PARTICIPANT_ALREADY_HAS_ASSIGNED_TURN:
    'participant-already-has-assigned-turn',
  PARTICIPANT_TURN_SUPERIMPOSITION: 'participant-turn-superimposition',
  OLD_TURN: 'old-turn',
  PROBLEMS_WITH_PARTICIPANT_SCHEDULE: 'problems-with-participant-schedule',
};

export const AGENDA_TABLES_TYPE = {
  invitation: 'invitation',
  confirmed: 'confirmed',
  host: 'host',
};

export const NoAssignmentReason = {
  no_free_turns: 'no_free_turns',
  no_schedule_space: 'no_schedule_space',
};

export const urlParameter = {
  QUERY: 'query',
  SECTOR: 'sector',
  SORT: 'sort',
  TYPE: 'type',
  ACTIVITY_FILTER: 'activity_filter',
  ARTICLE_TYPE: 'article_type',
  STATE: 'state',
  PRICE: 'price',
};

export const initialSearchFilters = {
  typeFilter: ['others'],
  articleTypeFilter: [],
  activityFilter: [],
  stateFilter: [],
  priceFilter: [],
  minPrice: '',
  maxPrice: '',
  page: 1,
  pageSize: SEARCH_PAGE_SIZE,
};

export const ActivityType = {
  MEETING: 'meeting',
  STREAMING: 'streaming',
  TALK: 'talk',
  DISCUSSION_TABLE: 'discussion_table',
  INSTITUTIONAL_TABLE: 'institutional_table',
  CONVERSATION: 'conversation',
  BUSINESS_ROUND: 'business_round',
  PUBLIC_ROUND: 'public_round',
  TRAINING: 'training',
  WORKSHOP: 'workshop',
};

export const devicesUseSite = [
  { option: 'pc', description: 'Escritorio' },
  { option: 'mobile', description: 'Celular' },
  { option: 'tablet', description: 'Tablet' },
  { option: 'bot', description: 'Robot' },
];

export const categorystatsAccount = {
  REGISTER: 'register',
  PORTFOLIO: 'portfolio',
};

export const colorsRegistersStats = {
  PRIMARY: '#61CDBB',
  SECUNDARY: '#E8A838',
};
export const paramsRegisterPortfolio = {
  category: categorystatsAccount.REGISTER,
  group_filter: 'portfolio',
};
export const paramsRegisterByProvince = {
  category: categorystatsAccount.REGISTER,
  group_filter: 'provincia',
};

export const paramsRegisterCountry = {
  category: categorystatsAccount.REGISTER,
  group_filter: 'country',
};
export const paramsRegisterCountryDefault = {
  country_filter: countryDefault,
  category: categorystatsAccount.REGISTER,
};

export const paramsPorfolioByProvince = {
  category: categorystatsAccount.PORTFOLIO,
  group_filter: 'provincia',
};
export const paramsPorfolioBySector = {
  category: categorystatsAccount.PORTFOLIO,
  group_filter: 'sector',
};

export const paletteColorStats = [
  '#ffa43a',
  '#f4ff91',
  '#ffbf75',
  '#759eff',
  '#75c7ff',
  '#525558',
  '#68da3e',
  '#00c6ab',
  '#6aa3b4',
  '#416864',
  '#223026',
  '#ff5f7c',
  '#e6a15c',
  '#9e6788',
  '#cbc56b',
  '#87ed94',
  '#00e7d9',
  '#ba78d7',
  '#9ea818',
  '#ec6000',
  '#3f8581',
  '#00a2ad',
  '#3d0168',
  '#005a53',
  '#7cbb6d',
  '#ba5263',
  '#a06b39',
  '#1f7656',
  '#e86d96',
];

export const SESSION_USER_TYPES = {
  register: 'register',
  anonymous: 'anonymous',
  new_user: 'new_user',
  count: 'count',
};

export const SECTOR_TABS = {
  OUTSTANDING: 'outstanding',
  CONTENT: 'content',
};

export const PRODUCERS_AND_STORE_TABS = {
  PRODUCERS: 'producers',
  STORE: 'store',
};

export const PROGRAM_TABS = [
  { NAME: 'action_point', ROUTE: 'puntos-de-accion' },
  { NAME: 'landing', ROUTE: 'landing' },
  { NAME: 'tags', ROUTE: 'etiquetas' },
  { NAME: 'follow', ROUTE: 'seguir' },
  { NAME: 'producers', ROUTE: 'productores' },
];

export const PROGRAM_TABS_NODE_RESPONSIBLE = [
  { NAME: 'action_point', ROUTE: 'puntos-de-accion' },
];

export const PROGRAM = {
  GENERAL_PRESENTATION: 'presentacion-general',
  MAIN_HIGHLIGHT: 'destacado-principal',
  MAP: 'mapa',
  SECONDARY_HIGHLIGHT: 'destacado-secundario',
};

export const SWITCHES = {
  DOMAIN: 'domain',
  NODE: 'node',
};

export const PROGRAM_POINT = {
  GENERAL_PRESENTATION: 'presentacion-general',
  HIGHLIGHT: 'destacado',
};

export const PROGRAM_POINT_TABS = {
  LANDING: 'landing',
  FOLLOWERS: 'followers',
};

export const PROGRAM_POINT_TABS_ALTERNATIVE = {
  LANDING: 'landing',
};

export const PROGRAM_POINT_TAB_FOR_RESPONSIBLE_PROGRAM = {
  FOLLOWERS: 'followers',
};

export const PROGRAM_MAP_FILTERS = {
  REGION: 'region',
  PROVINCE: 'province',
  LOCALITY: 'locality',
  TAGS: 'tags',
};

export const ITEM_TYPE = {
  DOMAIN: 'domain',
  NODE: 'node',
};

export const filtersUseSite = {
  COUNTRY: 'country',
  STATE: 'state',
  DEVICES: 'devices',
  SECTOR: 'sector',
};

export const AddToCalendarProviders = {
  Apple: 'Apple',
  Google: 'Google',
  Outlook: 'Outlook.com',
  iCal: 'iCal',
};

export const arrayAddToCalendar = [
  AddToCalendarProviders.Apple,
  AddToCalendarProviders.Google,
  AddToCalendarProviders.Outlook,
];

export const ID_SLIDERS = {
  ID_SLIDERS: 'id_slide',
  ID_FEATURED: 'id_featured',
};

export const customListActivities = {
  events: true,
  meeting: true,
  notes: false,
  collapsedNotes: false,
  collapsedShow: false,
  collapsedMeeting: false,
};

export const totalItemsCarrouselHome = 24;

export const STATISTICS_START_DATE = '2022-09-15T00:00:00';

export const PROGRAM_TAGS_MODAL = {
  ADD_TAG: 'addTag',
  EDIT_TAG: 'editTag',
  DELETE_TAG: 'deleteTag',
};

export const PROGRAM_LANDING_MODAL = {
  SWITCH_MAIN_HIGHLIGHT: 'switch_main_highlight',
  SWITCH_SECONDARY_HIGHLIGHTS: 'switch_secondary_highlights',
  SWITCH_MAP: 'switch_map',
  SWITCH_HIGHLIGHT: 'switch_highlight',
};

export const MAP_STYLE = {
  width: '100%',
  height: '100%',
  borderRadius: '10px',
  zIndex: 1,
};
export const TILE = {
  attribution:
    '&copy; <a href="https://www.ign.gob.ar/" target="_blank">Instituto Geográfico Nacional</a>',
  url: 'https://wms.ign.gob.ar/geoserver/gwc/service/tms/1.0.0/mapabase_gris@EPSG%3A3857@png/{z}/{x}/{-y}.png',
};
export const ZOOM_GLOBAL = 3;
export const ZOOM_LOCAL = 12;

export const MAP_ICON = {
  SIZE: {
    BIG: [40, 40],
    NORMAL: [32, 32],
    SMALL: [24, 24],
  },
  ANCHOR: {
    BIG: [21, 47.5],
    NORMAL: [16, 16],
    SMALL: [12, 15],
  },
  POP_UP_ANCHOR: {
    NORMAL: [0, -45],
    SMALL: [0, -8],
  },
};

export const masonryOptions = {
  horizontalOrder: true,
};

export const LINKED_ACCOUNTS_STATES = {
  INTEGRATION_COMPLETE: 'integration_complete',
  INTEGRATION_INCOMPLETE: 'integration_incomplete',
};

export const LINKED_CC_PRODUCTS_STATES = {
  PENDING: 'Pending',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
  TO_PUBLISH: 'To Publish',
  PUBLISHED: 'Published',
  OBSERVED: 'Observed',
  UPDATED: 'Updated',
  OUT_OF_STOCK: 'Out of Stock',
};

export const LINKED_PRODUCTS_STATES = {
  PENDING: 'Pending',
  PUBLISHED: 'Published',
  REJECTED: 'Rejected',
};

export const HIGHLIGHT_STATES = {
  DEFAULT: {
    ICON: 'highlight',
    COLOR: '#8B8B8B',
    VIEWBOX: '0 0 22 18',
  },
  HIGHLIGHTED: {
    ICON: 'highlight_filled',
    COLOR: '#1C6BFB',
    VIEWBOX: '0 0 22 18',
  },
};
