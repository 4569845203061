import { PRICE_FILTERS } from '../utils/Constants';
import axiosInstanceWithInterceptors from './InterceptorsForAxiosRequests';
//Ver la documentación en https://mica.dev.arsat.com.ar/api/search/docs
const SEARCH_API_URL = '/search/';

class SearchService {
  getPortfolios = (params) => {
    let filters = params.userName
      ? '?username=' + encodeURIComponent(params.userName)
      : '?';
    filters += params.id ? '&id_portfolio=' + params.id : '';
    filters += params.pageSize ? '&page_size=' + params.pageSize : '';
    filters += params.pageNumber ? '&page_num=' + params.pageNumber : '';
    filters += params.sector ? '&sector=' + params.sector : '';
    filters += params.workflow ? '&workflow=' + params.workflow : '';
    filters += params.q ? '&q=' + params.q : '';
    return axiosInstanceWithInterceptors.get(
      `${SEARCH_API_URL}portfolios` + filters
    );
  };

  getSections(params) {
    let filters = params.name
      ? '?name_section=' + encodeURIComponent(params.name)
      : '?';
    filters += params.id_section ? '&id_section=' + params.id_section : '';
    filters += params.id_user ? '&user_id=' + params.id_user : '';
    filters += params.pageSize ? '&page_size=' + params.pageSize : '';
    filters += params.pageNumber ? '&page_num=' + params.pageNumber : '';
    return axiosInstanceWithInterceptors.get(
      `${SEARCH_API_URL}sections` + filters
    );
  }
  getSectionContent(id) {
    return axiosInstanceWithInterceptors.get(
      `${SEARCH_API_URL}sections/content/` + id
    );
  }

  getArticles(params) {
    let filters = params.title
      ? '?title=' + encodeURIComponent(params.title)
      : '?';
    if (params.id_articles && params.id_articles?.length !== 0) {
      params.id_articles.map((id) => (filters += '&id_articles=' + id));
    }
    filters += params.id_user ? '&user_id=' + params.id_user : '';
    filters += params.type ? '&type_filter=' + params.type : '';
    filters += params.pageSize ? '&page_size=' + params.pageSize : '';
    filters += params.pageNumber ? '&page_num=' + params.pageNumber : '';
    filters += params.sector ? '&sector=' + params.sector : '';
    filters += params.workflow ? '&workflow=' + params.workflow : '';
    filters += params.q ? '&q=' + params.q : '';
    return axiosInstanceWithInterceptors.get(
      `${SEARCH_API_URL}articles` + filters
    );
  }
  getUserRelatedArticles(user_id, idToExclude) {
    let searchParams = new URLSearchParams({ id_item: idToExclude });
    return axiosInstanceWithInterceptors.get(
      `${SEARCH_API_URL}` + user_id + '?' + searchParams.toString()
    );
  }

  getRelatedArticlesBySector(sector, idToExclude) {
    let searchParams = new URLSearchParams({ id_item: idToExclude });
    return axiosInstanceWithInterceptors.get(
      `${SEARCH_API_URL}interest/` + sector + '?' + searchParams.toString()
    );
  }

  getSearch(params) {
    let searchParams = new URLSearchParams(params);
    searchParams.delete('sectors');
    searchParams.delete('service_type');
    searchParams.delete('article_type');
    searchParams.delete('id_state');
    searchParams.delete('price');
    searchParams.delete('activity_filter');
    params.sectors?.map((sector) => searchParams.append('sector', sector));
    params.activity_filter?.map((activityFilter) =>
      searchParams.append('activity_filter', activityFilter)
    );
    params.service_type?.map((type) =>
      searchParams.append('service_type', type)
    );
    params.article_type?.map((articleType) =>
      searchParams.append('article_type', articleType)
    );
    params.id_state?.map((state) => searchParams.append('id_state', state));
    params.price[0] !== PRICE_FILTERS.ANY &&
      params.price?.map((price) => searchParams.append('price', price));

    return axiosInstanceWithInterceptors.get(
      `${SEARCH_API_URL}?` + searchParams.toString()
    );
  }

  getHome() {
    return axiosInstanceWithInterceptors.get(`${SEARCH_API_URL}home`);
  }
  getHomeSectors(sector, cache = false) {
    return axiosInstanceWithInterceptors.get(
      `${SEARCH_API_URL}home/sectors/${sector}?cache=${cache}`
    );
  }

  getQueEsMica() {
    return axiosInstanceWithInterceptors.get(`${SEARCH_API_URL}que-es-mica`);
  }

  getHomeActivities() {
    return axiosInstanceWithInterceptors.get(
      `${SEARCH_API_URL}home/activities`
    );
  }
  getHomeNotes() {
    return axiosInstanceWithInterceptors.get(`${SEARCH_API_URL}home/notes`);
  }

  getFAQ() {
    return axiosInstanceWithInterceptors.get(
      `${SEARCH_API_URL}home/frequent-questions`
    );
  }

  getActivities(params) {
    let searchParams = new URLSearchParams();
    if (params.is_passed !== undefined) {
      searchParams.append('is_passed', params.is_passed);
    }
    if (params.momentFilter !== undefined) {
      searchParams.append('moment_filter', params.momentFilter);
    }
    searchParams.append('page_num', params.page_num);
    searchParams.append('page_size', params.page_size);
    if (params.type_filter) {
      searchParams.append('type_filter', params.type_filter);
    }
    params.type_activity?.map((type) =>
      searchParams.append('type_activity', type)
    );
    params.sector_filter?.map((sector) =>
      searchParams.append('sector', sector)
    );

    params.cycles?.map((cycle) => searchParams.append('cycles', cycle));
    params.topics?.map((topics) => searchParams.append('topics', topics));
    params.status?.map((status) => searchParams.append('status', status));
    if (params.cycle_filter) {
      searchParams.append('cycle_filter', params.cycle_filter);
    }
    if (params.date_start) {
      searchParams.append('date_start', params.date_start);
    }
    if (params.date_from) {
      searchParams.append('date_from', params.date_from);
    }
    if (params.date_to) {
      searchParams.append('date_to', params.date_to);
    }
    if (params.location_filter) {
      searchParams.append('location', params.location_filter);
    }
    if (params.exclude_id) {
      searchParams.append('exclude_id', params.exclude_id);
    }
    if (params.title) {
      searchParams.append('title', params.title);
    }
    if (params.is_footer_activities_available) {
      searchParams.append(
        'is_footer_activities_available',
        params.is_footer_activities_available
      );
    }
    if (params.id_domain) {
      searchParams.append('id_domain', params.id_domain);
    }
    if (params.id_node) {
      searchParams.append('id_node', params.id_node);
    }
    return axiosInstanceWithInterceptors.get(
      `${SEARCH_API_URL}activities/?` + searchParams.toString()
    );
  }

  getActivity(id) {
    return axiosInstanceWithInterceptors.get(
      `${SEARCH_API_URL}activities/` + id
    );
  }

  getActivityByEventId = async (params) => {
    return await axiosInstanceWithInterceptors.get(
      `${SEARCH_API_URL}activities/${params.type}/${params.id}`
    );
  };

  getCycles(params) {
    let searchParams = new URLSearchParams();
    if (params?.list_in_main_menu) {
      searchParams.append('list_in_main_menu', params?.list_in_main_menu);
    }
    return axiosInstanceWithInterceptors.get(
      `${SEARCH_API_URL}public/cycles?` + searchParams.toString()
    );
  }

  getPrograms() {
    return axiosInstanceWithInterceptors.get(
      `${SEARCH_API_URL}home/programs?list_in_main_menu=true`
    );
  }

  getPortfoliosFeatured() {
    return axiosInstanceWithInterceptors.get(
      `${SEARCH_API_URL}portfolios/featured`
    );
  }
  getArticlesFeatured() {
    return axiosInstanceWithInterceptors.get(
      `${SEARCH_API_URL}articles/featured`
    );
  }

  getNovelty(idNovelty) {
    return axiosInstanceWithInterceptors.get(
      `${SEARCH_API_URL}activities/novelty/${idNovelty}`
    );
  }
}

// eslint-disable-next-line
export default new SearchService();
