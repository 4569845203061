import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { ActivityCard } from '../../../../components/Activities/ActivityCard';
import { theme } from '../../../../theme';
import { useActivitiesStyles } from './useActivitiesStyles';
import SkipLink from '../../../../shared/Elements/SkipLinks/SkipLink';

function HomeActivities({ activities }) {
  const { t } = useTranslation();
  const classes = useActivitiesStyles();
  const isUpLg = useMediaQuery(theme.breakpoints.up('lg'));
  const isUpMd = useMediaQuery(theme.breakpoints.up('md'));
  const isUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const viewMore = '/actividades';

  if (!activities) {
    return null;
  }

  return (
    <section
      id='activities'
      aria-label={t('home.activities.label', {
        size: activities?.length,
      })}
    >
      <Box className={classes.containerActivities}>
        <SkipLink href={`#multifeatured`}>
          {t('home.activities.skip-to-multifeatured')}
        </SkipLink>
        <div className={classes.activityCardsWrapper}>
          {activities
            ?.slice(0, isUpMd ? 4 : isUpSm ? 2 : 3)
            .map((activity, index) => (
              <ActivityCard
                key={index}
                activity={activity}
                isHome={true}
                hasShadow={false}
                fromHome={true}
              />
            ))}
        </div>

        <div className={classes.upperActivityCardsWrapper}>
          {isUpLg && (
            <img
              src={`${process.env.PUBLIC_URL}/icons/diagonal-arrow.svg`}
              alt=''
              width='16'
              height='16'
            />
          )}
          <div className={classes.containerTitle}>
            <Typography variant='h1' className={classes.title}>
              {t('home.activities.title')}
            </Typography>
            <Link className={classes.button} to={viewMore}>
              {t('sectors.viewMore.more')}
            </Link>
          </div>
        </div>
      </Box>
    </section>
  );
}

export default HomeActivities;
