import {
  Box,
  ClickAwayListener,
  Collapse,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { NavbarStyles } from '../NavbarStyles';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MICALogo } from '../MICALogo';
import { onKeyDownEscape } from '../../utils/formHelper';
import ButtonCycleMenu from './LateralMenuComponents/ButtonCycleMenu';
import ButtonSectorMenu from './LateralMenuComponents/ButtonSectorMenu';
import ButtonProgramMenu from './LateralMenuComponents/ButtonProgramMenu';
import SearchService from '../../services/SearchService';

const LateralMenuNavbar = ({
  menuOpen,
  setMenuOpen,
  closeMenu,
  isAdmin,
  isMobile,
  setMenuSectorsOpen,
  menuSectorsOpen,
  menuSectorsAnchorRef,
  setMenuCyclesOpen,
  menuCyclesOpen,
  menuCyclesAnchorRef,
  setMenuDomainsOpen,
  menuDomainsOpen,
  menuDomainsAnchorRef,
  isLargeWindow,
  selectedMenuItem,
  setSelectedMenuItem,
  isLoadingTokenUser,
}) => {
  const classes = NavbarStyles();
  const menuAnchorRef = useRef(null);
  const { t } = useTranslation();
  const [cycles, setCycles] = useState([]);
  const [domains, setDomains] = useState([]);

  useEffect(() => {
    if (isLoadingTokenUser === false) getInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingTokenUser]);

  const getInitialData = async () => {
    try {
      const { data } = await SearchService.getPrograms();
      setCycles(data?.cycles);
      setDomains(data?.domains);
    } catch (error) {
      console.error(error);
    }
  };

  const labelMenu = () => {
    return isLargeWindow
      ? t('accessibility.navbar.lateralMenu.button') +
          t('accessibility.navbar.lateralMenu.tab')
      : t('accessibility.navbar.lateralMenu.button');
  };

  const onClickAwayLateralMenu = (event) => {
    if (menuAnchorRef.current && menuAnchorRef.current.contains(event.target)) {
      return;
    }
    closeMenu();
  };

  const onClickMenu = () => {
    menuOpen ? closeMenu() : setMenuOpen(true);
  };

  return (
    <Box
      onKeyDown={(e) => onKeyDownEscape(e, setMenuOpen)}
      className={classes.menuAndLogo}
    >
      <IconButton
        disableRipple
        className={classes.menuToggler}
        onClick={onClickMenu}
        ref={menuAnchorRef}
        aria-label={labelMenu()}
        role='button'
      >
        <MenuIcon />
      </IconButton>
      <Popper
        id='menuTooltip'
        open={menuOpen}
        anchorEl={menuAnchorRef.current}
        disablePortal
      >
        {({ TransitionProps }) => (
          <Collapse in={menuOpen} {...TransitionProps}>
            <ClickAwayListener onClickAway={onClickAwayLateralMenu}>
              <Paper
                elevation={0}
                className={classes.paperMenu}
                sx={{
                  maxHeight:
                    isAdmin && isMobile
                      ? 'calc(100vh - 128px)'
                      : !isAdmin && isMobile
                      ? 'calc(100vh - 64px)'
                      : isAdmin
                      ? 'calc(100vh - 128px + 238px)'
                      : 'calc(100vh - 64px + 238px)',
                  overflowY: 'auto',
                }}
              >
                <MenuList className={classes.expandableMainMenu}>
                  <MenuItem
                    className={classes.mainMenuItemWrapper}
                    to={'/que-es-mica'}
                    style={{ textDecoration: 'none' }}
                    onClick={closeMenu}
                    component={Link}
                  >
                    <Typography className={classes.mainMenuItem}>
                      {t('navbar.links.main-menu.what-is-mica')}
                    </Typography>
                  </MenuItem>
                  <ButtonSectorMenu
                    isMobile={isMobile}
                    menuSectorsOpen={menuSectorsOpen}
                    setMenuSectorsOpen={setMenuSectorsOpen}
                    menuSectorsAnchorRef={menuSectorsAnchorRef}
                    isLargeWindow={isLargeWindow}
                    selectedMenuItem={selectedMenuItem}
                    setSelectedMenuItem={setSelectedMenuItem}
                    setMenuCyclesOpen={setMenuCyclesOpen}
                    menuCyclesOpen={menuCyclesOpen}
                    menuCyclesAnchorRef={menuCyclesAnchorRef}
                    setMenuDomainsOpen={setMenuDomainsOpen}
                    menuDomainsOpen={menuDomainsOpen}
                    menuDomainsAnchorRef={menuDomainsAnchorRef}
                  />

                  {domains && domains?.length > 0 && (
                    <ButtonProgramMenu
                      isMobile={isMobile}
                      setMenuDomainsOpen={setMenuDomainsOpen}
                      menuDomainsOpen={menuDomainsOpen}
                      menuDomainsAnchorRef={menuDomainsAnchorRef}
                      menuCyclesOpen={menuCyclesOpen}
                      setMenuCyclesOpen={setMenuCyclesOpen}
                      menuCyclesAnchorRef={menuCyclesAnchorRef}
                      isLargeWindow={isLargeWindow}
                      selectedMenuItem={selectedMenuItem}
                      setSelectedMenuItem={setSelectedMenuItem}
                      setMenuSectorsOpen={setMenuSectorsOpen}
                      domains={domains}
                    />
                  )}

                  {cycles && cycles?.length > 0 && (
                    <ButtonCycleMenu
                      isMobile={isMobile}
                      menuCyclesOpen={menuCyclesOpen}
                      setMenuCyclesOpen={setMenuCyclesOpen}
                      menuCyclesAnchorRef={menuCyclesAnchorRef}
                      isLargeWindow={isLargeWindow}
                      selectedMenuItem={selectedMenuItem}
                      setSelectedMenuItem={setSelectedMenuItem}
                      setMenuSectorsOpen={setMenuSectorsOpen}
                      setMenuDomainsOpen={setMenuDomainsOpen}
                      menuDomainsOpen={menuDomainsOpen}
                      menuDomainsAnchorRef={menuDomainsAnchorRef}
                      cycles={cycles}
                    />
                  )}

                  <MenuItem className={classes.mainMenuItemWrapper}>
                    <Link
                      to={'/resultados-busqueda/?query=&type=perfil'}
                      onClick={closeMenu}
                      style={{ textDecoration: 'none' }}
                    >
                      <Typography className={classes.mainMenuItem}>
                        {t('navbar.links.main-menu.producers_profiles')}
                      </Typography>
                    </Link>
                  </MenuItem>
                  <MenuItem className={classes.mainMenuItemWrapper}>
                    <Link
                      to={'/actividades'}
                      onClick={closeMenu}
                      style={{ textDecoration: 'none' }}
                    >
                      <Typography className={classes.mainMenuItem}>
                        {t('navbar.links.main-menu.activities')}
                      </Typography>
                    </Link>
                  </MenuItem>
                  <MenuItem className={classes.mainMenuItemWrapper}>
                    <Link
                      to={'/notas'}
                      onClick={closeMenu}
                      style={{ textDecoration: 'none' }}
                    >
                      <Typography className={classes.mainMenuItem}>
                        {t('navbar.links.main-menu.notes')}
                      </Typography>
                    </Link>
                  </MenuItem>
                </MenuList>
              </Paper>
            </ClickAwayListener>
          </Collapse>
        )}
      </Popper>
      <Box className={classes.logoWrapper}>
        {!isMobile ? (
          <MICALogo width={71} height={18} hasDetail={isLargeWindow} inNavbar />
        ) : (
          <MICALogo width={50} height={14} inNavbar />
        )}
      </Box>
    </Box>
  );
};

export default LateralMenuNavbar;
