import { forwardRef } from 'react';
import { Button, Tooltip, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import classnames from 'classnames';

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    transition: theme.transitions.create('background-color'),
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.secondary.light,
    },
    fontWeight: 'light',
    padding: theme.spacing(0.5, 2),
    margin: theme.spacing(0.5),
    borderRadius: theme.artistActionButtons.borderRadius,
    fontSize: '1rem',
    '& span': {
      textTransform: 'initial',
      display: 'flex',
    },
  },
  buttonIcon: {
    marginRight: theme.spacing(1),
  },
  disabled: {
    backgroundColor: theme.palette.secondaryAccent.extraLight,
    color: `${theme.palette.secondaryAccent.contrastText} !important`,
  },
}));

export const CallToActionPrimary = forwardRef((props, ref) => {
  const classes = useStyles();

  const extraClasses = Array.isArray(props.classes)
    ? props.classes
    : [props.classes];

  return (
    <Tooltip title={!props.disabled && props?.title ? props?.title : ''}>
      <Button
        ref={props.forwardedRef}
        classes={{ disabled: classes.disabled }}
        className={classnames(classes.button, ...extraClasses)}
        onClick={(e) => props.onClickHandler?.(e)}
        disabled={props.disabled}
        fullWidth={props.fullWidth}
        onBlur={(e) => props.onBlur?.(e)}
      >
        <Typography variant='button'>
          {props.icon && props.icon(classes.buttonIcon)}
          {props.label}
        </Typography>
      </Button>
    </Tooltip>
  );
});
