import makeStyles from '@mui/styles/makeStyles';

export const useHomeStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: theme.spacing(0, 0, 3, 0),
    maxWidth: '100%',
    padding: theme.spacing(0, 0, 0, 0),
    width: 1360,
    [theme.breakpoints.up('lg')]: {
      margin: 'auto',
    },
  },
  boxMainHome: {
    minHeight: '100vh',
  },
  multiHighlightContainer: {
    margin: 'auto',
    maxWidth: '100%',
    minHeight: '100vh',
    paddingBottom: theme.spacing(3.75),
    width: 1360,
    [theme.breakpoints.down('sm')]: {
      minHeight: 700,
      padding: 0,
    },
  },
  moreMica: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 20,
    fontWeight: 600,
    height: 120,
    borderLeft: '1px solid white',
    [theme.breakpoints.down('md')]: {
      borderLeft: 'none',
      height: 70,
    },
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
    },
  },
  moreMicaText: {
    paddingLeft: '15%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      paddingLeft: 0,
      paddingRight: 24,
      paddingBottom: 20,
    },
  },
  linkAboutMica: { textDecoration: 'none', color: theme.palette.primary.green },
  arrowForward: { transform: 'rotate(-45deg)', fontSize: '30px' },
  sectorCard: {
    alignContent: 'flex-end',
    alignItems: 'center',
    backgroundColor: 'grey',
    borderRadius: 6,
    display: 'flex',
    height: 80,
    justifyContent: 'space-around',
    marginLeft: theme.spacing(1.125),
    marginRight: theme.spacing(1.125),
    width: 210,
    [theme.breakpoints.down('md')]: {
      height: 50,
      width: 130,
    },
    '&:hover': {
      backgroundColor: '#E3313D',
      backgroundImage: 'url() !important',
    },
  },
  sectorCardTitle: {
    color: '#FFF',
    fontSize: 16,
    fontWeight: 700,
    lineHeight: '19.5px',
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      fontSize: 24,
      lineHeight: '24.38px',
    },
  },
  discoverSubtitle: {
    fontWeight: 500,
    margin: theme.spacing(0.5, 0),
    paddingLeft: theme.spacing(1),
    [theme.breakpoints.down('lg')]: {
      fontSize: '0.8rem',
    },
  },
  videoSectionVideo: {
    minHeight: 693,
    maxHeight: 693,
    position: 'relative',
    [theme.breakpoints.down('lg')]: {
      minHeight: 'calc(100vw*0.5625 - 32px)',
      maxHeight: 400,
    },
    [theme.breakpoints.down('sm')]: {
      maxHeight: 300,
    },
    '& > div': {
      float: 'left',
      position: 'absolute',
    },
  },
  boxSlider: {
    position: 'relative',
    backgroundColor: '#333333',
    minHeight: 200,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      minHeight: 'calc(100vw*0.6)',
    },
    [theme.breakpoints.up('lg')]: {
      minHeight: 300,
    },
  },
  sectorBoxWrapper: {
    position: 'relative',
    backgroundColor: '#262626',
    minHeight: 110,
    [theme.breakpoints.up('sm')]: {
      minHeight: 100,
    },
    [theme.breakpoints.up('lg')]: {
      minHeight: 131,
    },
  },
  sectorBox: {
    backgroundColor: '#262626',
    color: '#F2F2F2',
    display: 'flex',
    flexWrap: 'wrap',
    margin: 'auto',
    alignItems: 'center',
    maxWidth: 1150,
    fontSize: 16,
    justifyContent: 'left',
    padding: theme.spacing(6, 2, 3, 0),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(1.625, 0),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3, 2, 3, 10),
      paddingTop: '50px',
      paddingBottom: '50px',
    },
    [theme.breakpoints.down('lg')]: {
      paddingLeft: theme.spacing(2),
    },
  },
  sectorContainer: {
    [theme.breakpoints.down('md')]: {
      paddingRight: 24,
      paddingLeft: 24,
    },
  },
  gridContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  sectorName: {
    fontSize: 20,
    whiteSpace: 'nowrap',
    [theme.breakpoints.up('sm')]: {
      fontSize: 22,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 18,
    },
  },
  sectorDot: {
    fontSize: 16,
    margin: theme.spacing(0, 1.5),
    whiteSpace: 'nowrap',
    '&:last-child': {
      display: 'none',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 17,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 20,
    },
  },
  multiHighlightAndNotesWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
    },
  },
  multiFeaturedWrapper: {
    position: 'relative',
    padding: theme.spacing(0),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0, 6.25),
    },
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(0),
      width: 'calc(50% - 16px)',
    },
  },
  activitiesWrapper: {
    margin: 'auto',
  },
  notesWrapper: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0, 6.25),
    },
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(0),
      width: '50%',
    },
  },
  falseButton: {
    backgroundColor: theme.palette.primary.white,
    border: 'none',
    borderRadius: 30,
    color: 'black',
    display: 'inline-block',
    fontSize: 13,
    fontWeight: 500,
    padding: theme.spacing(0.625, 2.5, 0.625, 2.5),
    textDecoration: 'none',
    '&:focus-visible': {
      outlineOffset: 4,
    },
    '&:hover': {
      backgroundColor: theme.palette.notification.border,
    },
    [theme.breakpoints.up('sm')]: {
      borderRadius: 26,
      padding: theme.spacing(0.625, 3.5, 0.625, 3.5),
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 16,
      fontWeight: 600,
    },
  },
}));
